<template>
  <div class="mupao-content">
    <h2 v-show="notEn">{{$t('lang.home.mupaoPage.title')}}</h2>
    <p class="e_des">
      MUPAO
      <br>
      ADVERTISING ALLIANCE
    </p>
    <p class="hr"></p>
    <p v-show="notEn" class="txt">
      {{$t('lang.home.mupaoPage.des')}}
    </p>
    <p class="txt base-size">
      Mupao advertising alliance is an Internet marketing company focusing on Internet advertising and network
      marketing. It has rich experience in network advertising and network planning. It focuses on the research and
      development of online advertising and provides various forms of online advertising service for customers.
    </p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "dcloudContent",
  computed:{
    notEn(){
      return this.$i18n.locale !=='en'
    },
  },
}
</script>

<style lang="less" scoped>

.mupao-content {
  position: absolute;
  width: 640px;
  left: 18%;
  top: 35%;
  font-weight: normal;
  text-align: left;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  > h2 {
    font-size: 40px;
    font-weight: 500;
  }

  .e_des {
    margin-top: 20px;
    font-size: 24px;
  }
}

</style>
